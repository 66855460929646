function toggleMenu() {
  var menuBtn = document.querySelector(".menu-btn");
  var closeBtn = document.querySelector(".mobile-menu__close");
  var mobileMenu = document.querySelector(".mobile-menu");

  menuBtn.addEventListener("click", function () {
    mobileMenu.classList.add("mobile-menu--open");
  });

  closeBtn.addEventListener("click", function () {
    mobileMenu.classList.remove("mobile-menu--open");
  });
}

export default toggleMenu;